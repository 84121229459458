//!---------------Admin------------------------
:root {
  /**
    @font family declaration
    */
  --tp-ff-body: 'Archivo', sans-serif;
  --tp-ff-heading: 'Archivo', sans-serif;
  ;
  --tp-ff-p: 'Archivo', sans-serif;
  ;
  --tp-ff-fontawesome: "Font Awesome 5 Pro";
  /**
    @color declaration
    */
  --tp-common-white: #ffffff;
  --tp-common-black: #070707;
  --tp-common-dark: #0b0b0b;
  --tp-common-dark-2: #0f0f0f;
  --tp-heading-primary: #070707;
  --tp-grey-1: #777777;
  --tp-grey-2: #acb3b7;
  --tp-grey-3: #757c80;
  --tp-grey-4: #999999;
  --tp-grey-5: #bbbbbb;
  --tp-grey-6: #c8c8c8;
  --tp-grey-7: #6e6e6e;
  --tp-grey-8: #a6a6a6;
  --tp-text-body: #000;
  --tp-text-1: #000;
  --tp-theme-bg: #f8fcfc;
  --tp-theme-bg-2: #f6f6f6;
  --tp-theme-bg-3: #f8fbff;
  --tp-theme-bg-4: #d3dbdf;
  --tp-theme-bg-5: #eef4f7;
  --tp-theme-bg-6: #f7f7f7;
  --tp-theme-bg-7: #f2f5f7;
  --tp-theme-bg-8: #e5eaee;
  --tp-theme-bg-9: #ecf8ff;
  --tp-theme-redical: #ff3c58;
  --tp-theme-red: #c5002e;
  --tp-theme-cornblue: #6c60fe;
  --tp-theme-vogue: #032b5f;
  --tp-theme-orange: #ff3221;
  --tp-theme-mirage: #1b1c31;
  --tp-theme-bouquet: #b685a1;
  --tp-theme-wine: #50223c;
  --tp-theme-mandalay: #b08920;
  --tp-theme-seagreen: #0d496b;
  --tp-theme-lochmara: #0686D8;
  --tp-theme-atlantis: #80BE2D;
  --tp-theme-picton: #5FB0E5;
  --tp-border-1: #e6e6e6;
  --tp-border-2: #dce9e9;
  --tp-border-3: #ececec;
  --tp-border-4: #efefef;
  --tp-border-5: #f0f0f0;
  --tp-border-6: #f3f3f3;
  --tp-border-7: #e4e4e4;
  --tp-border-8: #ededed;
  --tp-border-9: #f0f0f0;
  --tp-border-10: #1c1c1c;
  --tp-border-11: #ebebeb;
  --tp-border-12: #e7e7e7;
}

//!-----------------Dashboard--------

.dash-menu-items a,
.dash-menu-items button {

  &.active,
  &:hover {
    background-color: #124e89;
    color: #fef2f2;

    span {
      background-color: #fef2f2;
    }

    .icon {
      background: white;
      color: #124e89;
    }
  }


  .icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #124e89;
    color: #fff;
    border-radius: 7px;
    box-shadow: #124e89, #0000, #0000;
  }
}

.dash-menu-items a.active {
  background-color: #ff3c58;
  color: #fef2f2;

  span {
    background-color: #fef2f2;
  }
}

// .nav-links a {
//   color: #124e89;
// }

.nav-links a.scroll {

  &.active {
    background-color: #ff3c58;
    color: #fff;
  }

  color: #fff;
}

//!----dash burger mobile header

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 40px;
  cursor: pointer;
  margin: auto;
  display: block;
  height: calc(4px * 3 + 11px * 2);
}

.bar {
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: calc(4px / 2);
  background: white;
  color: inherit;
  opacity: 1;
  -webkit-transition: none 0.35s cubic-bezier(0.5, -0.35, 0.35, 1.5) 0s;
  transition: none 0.35s cubic-bezier(0.5, -0.35, 0.35, 1.5) 0s;
}

/***** Spin Animation *****/

.bar--top {
  bottom: calc(50% + 11px + 4px / 2);
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform;
  -webkit-transition-delay: calc(0s + 0.35s), 0s;
  transition-delay: calc(0s + 0.35s), 0s;
}

.bar--middle {
  top: calc(50% - 4px / 2);
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-delay: calc(0s + 0.35s);
  transition-delay: calc(0s + 0.35s);
}

.bar--bottom {
  top: calc(50% + 11px + 4px / 2);
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, -webkit-transform;
  transition-property: top, transform;
  transition-property: top, transform, -webkit-transform;
  -webkit-transition-delay: calc(0s + 0.35s), 0s;
  transition-delay: calc(0s + 0.35s), 0s;
}

#checkbox:checked+.toggle .bar--top {
  bottom: calc(50% - 4px / 2);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition-delay: 0s, calc(0s + 0.35s);
  transition-delay: 0s, calc(0s + 0.35s);
}

#checkbox:checked+.toggle .bar--middle {
  opacity: 0;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: calc(0s + 0.35s);
  transition-delay: calc(0s + 0.35s);
}

#checkbox:checked+.toggle .bar--bottom {
  top: calc(50% - 4px / 2);
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition-delay: 0s, calc(0s + 0.35s);
  transition-delay: 0s, calc(0s + 0.35s);
}

//!--------copy to clipboard button for generated codes
.code-btn {
  border-radius: 0.3rem;
  padding: 0.2rem 0.4rem;
  transition: all 0.3s ease-in-out;
  border: 2px solid #f9b522;
  color: #fef2f2;
  background-color: #f9b522;
}

.code-btn:hover {
  background-color: transparent;
  color: #f9b522;
}

.code-btn:active {
  transform: scale(0.9);
}

//!--------place the sorting arrows besides the column name
.ant-table-column-sorters {
  display: inline-flex !important;
  align-items: center !important;
}

.ant-table-filter-dropdown-btns {
  .ant-btn-primary {
    background-color: rgb(14 165 233);
  }
}

//!---------- visibility-switch ----------

/* The visibility-switch - the box around the visibility-slider */
.visibility-switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.visibility-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The visibility-slider */
.visibility-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fba6a66f;
  border: 2px solid #124e89;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 32px;
  outline: none;
}

.visibility-slider:before {
  position: absolute;
  content: "";
  height: 2em;
  width: 2em;
  border-radius: 50%;
  outline: 2px solid #124e89;
  left: -1px;
  bottom: -1px;
  background-color: #fef2f2;
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
}

.slider-icon {
  opacity: 0;
  height: 12px;
  width: 12px;
  stroke-width: 8;
  position: absolute;
  z-index: 999;
  stroke: #fef2f2;
  right: 60%;
  top: 30%;
  -webkit-transition: right ease-in-out 0.3s, opacity ease-in-out 0.15s;
  transition: right ease-in-out 0.3s, opacity ease-in-out 0.15s;
}

.visibility-switch input:checked+.visibility-slider {
  background-color: #fba6a6;
}

.visibility-switch input:checked+.visibility-slider .slider-icon {
  opacity: 1;
  right: 16%;
}

.visibility-switch input:checked+.visibility-slider:before {
  -webkit-transform: translateX(1.5em);
  -ms-transform: translateX(1.5em);
  transform: translateX(1.5em);
  background-color: #124e89;
}

//!--grid-auto-fit-------

.grid-auto-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 3rem;
  justify-items: center;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.video-grid-auto-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 3rem;
  justify-items: center;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

/*single-course---------------------*/
.course-details-wrapper {
  background-image: url("../../public/assets/pattern.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.code-input {
  transition: box-shadow 0.3s ease-in-out;
  border: 2px solid #124e89;
  color: #124e89;
}

.code-input::placeholder {
  color: #124e8966;
  opacity: 0.5;
  font-weight: 600;
}

.code-input:focus {
  background-color: #124e891a;
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px #0b9d58;
}

video[poster] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.w-session {
  width: calc(100% - 25px);
}

.w-lock {
  width: 25px;
}

.teacher-bg-pattern {
  animation: teacherBgPattern 15s linear infinite;
}

@keyframes teacherBgPattern {
  to {
    transform: rotate(360deg);
  }
}


/*----------------------------------------*/
/*  26. FOOTER CSS
  /*----------------------------------------*/
.bs-footer__top-social a,
.da-footer__top-social a {
  font-size: 16px;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 2px solid #f3f3f3;
  text-align: center;
  line-height: 46px;
  margin-right: 5px;
  border-radius: 8px;
}

.bs-footer__top-social a:hover,
.da-footer__top-social a:hover {
  border-color: transparent;
  color: #fff;
  background-color: #6c60fe;
}

.law-footer__main .tp-footer__widget ul li:hover a {
  color: white;
}

.about-me-socials .bs-footer__top-social a {
  border-color: transparent;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}

.bs-footer__circle {
  position: absolute;
  left: 180px;
  top: 55px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(145deg, rgb(246, 246, 246) 0%, rgb(255, 255, 255) 100%);
  background-image: -webkit-linear-gradient(145deg, rgb(246, 246, 246) 0%, rgb(255, 255, 255) 100%);
  background-image: -ms-linear-gradient(145deg, rgb(246, 246, 246) 0%, rgb(255, 255, 255) 100%);
  position: absolute;
  width: 204px;
  height: 204px;
  z-index: 1;
  animation: moving 5s ease-in-out infinite;
}

.da-footer__top-social a {
  color: #032b5f;
  background-color: #fff;
}

.da-footer__top-social a:hover {
  color: #fff;
  background-color: #ff3221;
}

.footer-blog-thum img {
  border-radius: 6px;
}

.footer-blog-info-meta {
  color: var(--tp-grey-1);
  font-size: 12px;
}

.footer-blog-info-meta b {
  color: #032b5f;
}

.footer-blog-info-meta b:hover {
  color: #ff3221;
}

.footer-blog .ft-blog-cta {
  color: #ff3221;
}

.footer-blog .ft-blog-cta:hover {
  color: #032b5f;
}

.footer-blog-info-title {
  font-size: 14px;
  line-height: 1.5;
}

.footer-blog-info-title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}

.footer-blog-info-title a:hover {
  background-size: 0 1px, 100% 1px;
}

.fotter-btn-bottom {
  border-bottom: 1px solid var(--tp-border-7);
}

.it-footer .tp-footer__widget-title {
  color: #032b5f;
}

.it-footer .ft-blog-cta {
  color: #ff3221;
}

.it-footer .footer-blog-info-title {
  color: #032b5f;
}

.tp-footer__widget-title {
  font-size: 16px;
  font-weight: 700;
}

.tp-footer__widget ul li a {
  font-size: 16px;
  margin-bottom: 15px;
  display: inline-block;
  color: var(--tp-grey-1);
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.tp-footer__widget ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #000;
  display: inline-block;
}

.tp-footer__widget ul li:hover a {
  color: var(--tp-common-black);
}

.tp-footer__widget ul li:hover a::after {
  right: auto;
  left: 0;
  width: 100%;
}

.tp-footer__widget ul#company {
  overflow: hidden;
}

.tp-footer__widget ul#company li {
  width: 50%;
  float: left;
}

.tp-footer__widget p {
  color: var(--tp-grey-1);
  font-size: 16px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .tp-footer__widget p {
    padding-right: 0;
  }
}

.tp-footer-cta .call-icon img {
  padding: 12px;
  border: 2px solid var(--tp-border-8);
  margin-right: 20px;
  border-radius: 4px;
}

.tp-footer-cta span p {
  color: var(--tp-theme-redical);
}

.tp-footer-cta span p.text-cornblue {
  color: #6c60fe;
}

.tp-footer-cta span b {
  font-size: 16px;
}

.tp-footer-cta span b :hover {
  color: var(--tp-grey-1);
}

.ha-footer .footer-blog-info-title:hover a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}

.ha-footer .footer-blog-info-title:hover a:hover {
  background-size: 0% 1px, 100% 1px;
}

.ha-footer ul li a::after {
  background-color: #fff;
}

.ha-footer ul li:hover a {
  color: var(--tp-common-black);
}

.ha-footer ul li:hover a::after {
  right: auto;
  left: 0;
  width: 100%;
}

.law-footer ul li a::after {
  background-color: #fff;
}

.law-footer ul li:hover a {
  color: var(--tp-common-black);
}

.law-footer ul li:hover a::after {
  right: auto;
  left: 0;
  width: 100%;
}

.tp-footer-from form input {
  height: 60px;
  padding-left: 50px;
  padding-right: 75px;
  width: 100%;
  border: 2px solid var(--tp-border-8);
  border-radius: 8px;
}

.tp-footer-from form input::-webkit-input-placeholder {
  color: var(--tp-grey-1);
}

.tp-footer-from form input:-moz-placeholder {
  color: var(--tp-grey-1);
}

.tp-footer-from form input::-moz-placeholder {
  color: var(--tp-grey-1);
}

.tp-footer-from form input:-ms-input-placeholder {
  color: var(--tp-grey-1);
}

.tp-footer-from form button {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background-color: var(--tp-theme-redical);
  color: #fff;
  border-radius: 8px;
}

.tp-footer-from form button:hover {
  background-color: #6c60fe;
}

.tp-footer-from form span i {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
}

.tp-footer-from-2 form input {
  height: 60px;
  padding-left: 50px;
  padding-right: 120px;
  width: 100%;
  font-size: 16px;
  border: 2px solid var(--tp-border-8);
  border-radius: 8px;
}

.tp-footer-from-2 form input::-webkit-input-placeholder {
  color: var(--tp-grey-1);
}

.tp-footer-from-2 form input:-moz-placeholder {
  color: var(--tp-grey-1);
}

.tp-footer-from-2 form input::-moz-placeholder {
  color: var(--tp-grey-1);
}

.tp-footer-from-2 form input:-ms-input-placeholder {
  color: var(--tp-grey-1);
}

.tp-footer-from-2 form button {
  position: absolute;
  top: 50%;
  right: 30px;
  font-size: 16px;
  font-weight: 700;
  transform: translateY(-50%);
  color: black;
  border-radius: 8px;
}

.tp-footer-from-2 form button:hover {
  color: var(--tp-theme-mandalay);
}

.tp-footer-from-2 form span i {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  color: var(--tp-theme-mandalay);
}

.tp-copyrigh-text {
  font-size: 16px;
  color: var(--tp-grey-1);
}

.tp-copyrigh-text a {
  font-weight: 700;
  color: var(--tp-common-black);
}

.tp-copyrigh-text a:hover {
  color: var(--tp-theme-redical);
}

.it-footer .tp-copyrigh-text a:hover {
  color: #ff3221;
}

.it-footer .tp-footer-menu ul li:last-child {
  margin-right: 0;
}

.it-footer .tp-footer-menu ul li:hover a {
  color: #ff3221;
}

.tp-footer-menu {
  font-size: 16px;
  font-weight: 700;
  color: var(--tp-common-black);
}

.tp-footer-menu ul li {
  display: inline-block;
  margin-right: 30px;
}

.tp-footer-menu ul li:last-child {
  margin-right: 0;
}

.tp-footer-menu ul li:hover a {
  color: var(--tp-theme-redical);
}

@media (max-width: 767px),
only screen and (min-width: 576px) and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-footer-cta {
    margin-bottom: 30px;
  }
}

.tp-form-note {
  color: var(--tp-common-black) !important;
}

.tp-form-note span {
  color: #6c60fe;
}

.bs-pl-60 {
  margin-left: 60px;
}

@media (max-width: 767px),
only screen and (min-width: 576px) and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .bs-pl-60 {
    margin-left: 0;
  }
}

.da-footer .tp-copyrigh-text a:hover,
.ca-footer .tp-copyrigh-text a:hover {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.da-footer .tp-footer-menu ul li:hover a,
.ca-footer .tp-footer-menu ul li:hover a {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.da-footer .tp-footer-from form button,
.ca-footer .tp-footer-from form button {
  background-image: linear-gradient(100.16deg, #5a6ef8 9.94%, #a874c6 54.82%, #fe7a8f 95.56%);
  color: #fff;
  border-radius: 8px;
}

.da-ft-social {
  color: var(--tp-common-black);
}

.da-ft-social span {
  padding: 0 8px;
  color: var(--tp-grey-1);
}

.da-ft-social span:hover {
  color: var(--tp-common-black);
}

.da-footer-bg {
  background-image: -moz-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(237, 243, 245) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(237, 243, 245) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(237, 243, 245) 100%);
}

.da-ft-copyright-bg {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.04);
  padding-left: 40px;
  padding-right: 40px;
}

.ptg-footer__top-social a {
  position: relative;
  font-size: 16px;
  display: inline-block;
  width: 50px;
  height: 50px;
  color: white;
  background-color: var(--tp-common-dark);
  border: 2px solid #262626;
  text-align: center;
  line-height: 49px;
  margin-right: 5px;
  border-radius: 8px;
  z-index: 12;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.ptg-footer__top-social a::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(100.16deg, #5a6ef8 9.94%, #a874c6 54.82%, #fe7a8f 95.56%);
  z-index: -1;
  opacity: 0;
}

.ptg-footer__top-social a:hover {
  color: #fff;
  border-color: transparent;
}

.ptg-footer__top-social a:hover::after {
  opacity: 1;
}

.ptg-footer-info span {
  color: var(--tp-grey-4);
  font-size: 18px;
  margin-bottom: 30px;
  display: inline-block;
}

@media (max-width: 767px) {
  .ptg-footer-info span {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .ptg-footer-info span br {
    display: none;
  }
}

.ptg-footer-info span a {
  color: white;
  font-weight: 700;
  text-decoration: underline;
}

.ptg-footer-copyright {
  font-size: 16px;
  color: var(--tp-grey-1);
}

.ptg-footer-copyright a {
  font-weight: 700;
  color: #fff;
}

.ptg-footer-copyright a:hover {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ptg-footer-menu {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.ptg-footer-menu ul li {
  display: inline-block;
  margin-right: 30px;
}

.ptg-footer-menu ul li:last-child {
  margin-right: 0;
}

.ptg-footer-menu ul li:hover a {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mp-footer-section-title {
  color: #fff;
  display: block;
  padding-bottom: 25px;
}

.mp-footer-title {
  padding-top: 30px;
  color: #fff;
  font-size: 60px;
  letter-spacing: -2px;
}

@media (max-width: 767px) {
  .mp-footer-title {
    font-size: 30px;
    letter-spacing: inherit;
  }
}

.mp-footer-title span {
  color: #323232;
}

.ft-mail-btn {
  color: #fff;
  font-size: 16px;
  border: 2px solid #171717;
  border-radius: 50%;
  padding: 25px 60px;
}

.ft-mail-btn:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.9);
}

.ft-phone-btn {
  color: #fff;
  font-size: 16px;
  border: 2px solid #171717;
  border-radius: 50%;
  padding: 25px 60px;
}

.ft-phone-btn:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.9);
}

.mp-footer-copyright {
  color: var(--tp-grey-4);
}

.mp-footer-copyright a {
  color: #fff;
}

.mp-footer-menu {
  font-size: 16px;
  font-weight: 700;
  color: var(--tp-grey-4);
}

.mp-footer-menu ul li {
  display: inline-block;
  margin-right: 15px;
}

.mp-footer-menu ul li:last-child {
  margin-right: 0;
}

.mp-footer-menu ul li:hover a {
  color: #fff;
}

.law-footer__main .tp-footer__widget-title {
  color: #fff;
}

.law-footer__main .tp-footer__widget ul li a {
  color: #bea8b4;
}

.law-footer__main .tp-footer__widget p {
  color: #bea8b4;
}

.law-footer__main .tp-footer-cta p span {
  color: #bea8b4;
}

.law-footer__main .tp-footer-cta p a {
  color: #fff;
}

.law-footer__main .tp-footer-cta .call-icon img {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
  border-color: transparent;
  background-color: #fff;
  border-radius: 8px;
}

.law-footer__main .tp-footer-from form input::-webkit-input-placeholder {
  color: #bbbbbb;
}

.law-footer__main .tp-footer-from form input:-moz-placeholder {
  color: #bbbbbb;
}

.law-footer__main .tp-footer-from form input::-moz-placeholder {
  color: #bbbbbb;
}

.law-footer__main .tp-footer-from form input:-ms-input-placeholder {
  color: #bbbbbb;
}

.law-footer__main .tp-footer-from form button {
  background-color: var(--tp-theme-red);
}

.law-footer__main .tp-footer-from form span i {
  color: var(--tp-theme-wine);
}

.law-footer__main .tp-form-note {
  color: #99798b !important;
}

.law-footer__main .tp-form-note span {
  color: #99798b;
}

.law-footer__main .da-ft-social {
  color: white !important;
}

.law-footer__main .da-ft-social span {
  color: #94808b;
}

.law-footer__main .da-ft-social span:hover {
  color: white;
}

.law-footer__bottom {
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}

.law-footer__bottom .tp-copyrigh-text {
  color: #fff;
}

.law-footer__bottom .tp-copyrigh-text a {
  color: white;
}

.law-footer__bottom .tp-copyrigh-text a:hover {
  opacity: 0.8;
}

.law-footer__bottom .tp-footer-menu {
  color: #fff;
}

.law-footer__bottom .tp-footer-menu ul li:hover a {
  opacity: 0.8;
  color: #fff;
}

.ca-footer .da-footer__top-social a {
  color: #000;
  position: relative;
  z-index: 3;
}

.ca-footer .da-footer__top-social a::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(100.16deg, #5a6ef8 9.94%, #a874c6 54.82%, #fe7a8f 95.56%);
  z-index: -1;
  opacity: 0;
}

.ca-footer .da-footer__top-social a:hover {
  color: white;
  background-color: inherit;
}

.ca-footer .da-footer__top-social a:hover::after {
  opacity: 1;
}

.ca-footer .tp-footer__widget-title {
  color: black;
}

.ca-footer .footer-blog-info-meta span a b {
  color: black;
}

.ha-footer .da-footer__top-social a {
  color: #939393;
  background-color: #111111;
  border-color: #111111;
}

.ha-footer .da-footer__top-social a:hover {
  color: white;
  background-color: var(--tp-theme-mandalay);
}

.ha-footer .tp-footer__widget-title {
  color: white;
}

.ha-footer .tp-footer__widget p {
  color: #b2b2b2;
}

.ha-footer .footer-blog-info-meta span a b {
  color: black;
}

.ha-footer .footer-blog-info-meta span {
  color: #b2b2b2;
  font-size: 16px;
}

.ha-footer .footer-blog-info-title {
  color: white;
}

.ha-footer .tp-border-bottom,
.ha-footer .tp-border-top,
.ha-footer .fotter-btn-bottom {
  border-color: #141414;
}

.ha-footer .tp-footer__widget ul li:hover a {
  color: white;
}

.ha-footer-copyright {
  background-color: var(--tp-theme-mandalay);
  box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.04);
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 12px 12px 0 0;
}

.ha-footer-copyright .tp-copyrigh-text {
  color: white;
}

.ha-footer-copyright .tp-copyrigh-text a {
  color: #fff;
}

.ha-footer-copyright .tp-copyrigh-text a:hover {
  opacity: 0.7;
}

.ha-footer-copyright .tp-footer-menu ul li a {
  color: white;
}

.ha-footer-copyright .tp-footer-menu ul li a:hover {
  opacity: 0.7;
}

.seo-footer .tp-footer__widget-title {
  color: var(--tp-theme-seagreen);
}

.seo-footer .da-footer__top-social a {
  color: var(--tp-theme-seagreen);
  font-size: 16px;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 2px solid #f3f3f3;
  text-align: center;
  line-height: 49px;
  margin-right: 5px;
  border-radius: 8px;
}

.seo-footer .da-footer__top-social a:hover {
  color: #fff;
  background-color: var(--tp-theme-lochmara);
}

.seo-footer .footer-blog-info-title {
  color: var(--tp-theme-seagreen);
}

.seo-footer .footer-blog-info-meta b {
  color: var(--tp-theme-seagreen);
}

.seo-footer .footer-blog-info-meta b.ft-blog-cta {
  color: var(--tp-theme-lochmara);
}

.seo-footer .tp-copyrigh-text a {
  color: var(--tp-theme-seagreen);
}

.seo-footer .tp-copyrigh-text a:hover {
  color: var(--tp-theme-lochmara);
}

.seo-footer .tp-footer-menu {
  color: var(--tp-theme-seagreen);
}

.seo-footer .tp-footer-menu a:hover {
  color: var(--tp-theme-lochmara);
}