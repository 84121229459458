.signin-inputs,
.submit,
textarea,
select,
input[type="file"] {
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  border: 2px solid #cfcfcf;
  border-radius: 8px;
  text-align: start;
  background-color: #fff;
}

textarea {
  height: 200px;
}


.submit {
  text-align: center;
}

.signin-inputs::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.5;
  font-size: 1.5rem;
  font-weight: 600;
}


label {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  width: 100%;
}

.submit {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 1.7rem;
  font-weight: 700;
}

.submit:active {
  transform: scale(0.9);
}

.submit:disabled {
  border: 2px solid #124d8989;
  color: rgba(18, 77, 137, 0.538);
}

.submit:disabled:active {
  transform: scale(1);
}




/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.question-submit {
  width: 250px;
}

//!------ permissions checkbox ---------

.checkbox-wrapper {
  position: relative;
}

.checkbox-wrapper>svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.checkbox-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper .cbx {
  width: 24px;
  height: 24px;
  top: calc(100px - 12px);
  left: calc(100px - 12px);
}

.checkbox-wrapper .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #c71f38;
  background-color: #fba6a66f;
  border-radius: 50%;
  padding: 0;
}

.checkbox-wrapper .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

.checkbox-wrapper .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper .cbx svg path {
  stroke: #fef2f2;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  -webkit-transition: stroke-dashoffset 0.3s ease;
  transition: stroke-dashoffset 0.3s ease;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.checkbox-wrapper .cbx input:checked+label {
  -webkit-animation: splash-12 0.6s ease forwards;
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper .cbx input:checked+label+svg path {
  stroke-dashoffset: 0;
}

@-webkit-keyframes splash-12 {
  40% {
    background: #c71f38;
    -webkit-box-shadow: 0 -18px 0 -8px #c71f38, 16px -8px 0 -8px #c71f38,
      16px 8px 0 -8px #c71f38, 0 18px 0 -8px #c71f38, -16px 8px 0 -8px #c71f38,
      -16px -8px 0 -8px #c71f38;
    box-shadow: 0 -18px 0 -8px #c71f38, 16px -8px 0 -8px #c71f38,
      16px 8px 0 -8px #c71f38, 0 18px 0 -8px #c71f38, -16px 8px 0 -8px #c71f38,
      -16px -8px 0 -8px #c71f38;
  }

  100% {
    background: #c71f38;
    -webkit-box-shadow: 0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent, -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: #c71f38;
    -webkit-box-shadow: 0 -18px 0 -8px #c71f38, 16px -8px 0 -8px #c71f38,
      16px 8px 0 -8px #c71f38, 0 18px 0 -8px #c71f38, -16px 8px 0 -8px #c71f38,
      -16px -8px 0 -8px #c71f38;
    box-shadow: 0 -18px 0 -8px #c71f38, 16px -8px 0 -8px #c71f38,
      16px 8px 0 -8px #c71f38, 0 18px 0 -8px #c71f38, -16px 8px 0 -8px #c71f38,
      -16px -8px 0 -8px #c71f38;
  }

  100% {
    background: #c71f38;
    -webkit-box-shadow: 0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent, -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}